import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDataFBO} from "../../../store/reducers/ActionCreators";
import {changeType} from "../../../store/reducers/StartMenuSlice";
import LinkButton from "../../buttons/LinkButton";
import {selectParentDetailFBO, selectTypeDetailFBO} from "../../utils/utils";
import {changeDetailDataQty} from "../../../store/reducers/SendSelectedQtyFboSlice";

const TypeFbo = () => {
    const dispatch = useDispatch();
    const {valueParent, valueDate, dataFBO, name} = useSelector(state => state.StartMenuStore);

    let jsxTable = [];

    useEffect(() => {
        dispatch(getDataFBO());
    }, [])

    const onClickType = (nameType) => {
        dispatch(changeType('DETAIL_FBO'));
        const {orders, totalCountProducts} = selectTypeDetailFBO(
            dataFBO, name, valueDate, valueParent, nameType);
        dispatch(changeDetailDataQty({totalCountProducts, orders}));
    }

    let textTitle = '';

    if (dataFBO) {
        const {typeData, totalCountProducts} = selectParentDetailFBO(dataFBO, name, valueDate, valueParent);
        let ind = 0;
        for (let [type, value] of typeData.entries()) {
            jsxTable.push(
                (
                    <tr key={ind}>
                        <td className={"text-center fs-3"}>{++ind}</td>
                        <td className={"text-center"}><LinkButton name={type} onClick={onClickType} /></td>
                        <td className={"text-center fw-bold fs-3"}>{value.qty}</td>
                    </tr>
                )
            )
        }
        textTitle = (
            <div className="alert alert-success align-items-center">
                <div className="d-flex flex-column">
                    <h6 className="mb-1 text-dark text-center">Сборка поставок FBО</h6>
                    <h4 className="mb-1 text-info text-center">{valueDate}</h4>
                    <h4 className="mb-1 text-info text-center">{valueParent}</h4>
                    <p className={'text-danger text-center'}>Количество не собранных товаров
                        <span className={'fw-bold'}>  {totalCountProducts}</span>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            {textTitle}
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr className="fw-bold fs-6 text-gray-800">
                            <th className={"text-center"}>#</th>
                            <th className={"text-center"}>Type</th>
                            <th className={"text-center"}>Quantity</th>
                        </tr>
                    </thead>

                    <tbody>
                        {jsxTable}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TypeFbo;