import React from 'react';
import Button from "../../buttons/Button";
import {useDispatch} from "react-redux";
import {changeType} from "../../../store/reducers/StartMenuSlice";

const Start = () => {
    const dispatch = useDispatch();
    return (
        <div>
           <Button onClick={() => {
               dispatch(changeType('PARENT'))
           }} name={"FBS - Сборка заказов"} className={"btn-primary hover-elevate-up form-control mb-5"} />
            <Button onClick={() => {
               dispatch(changeType('FBO'))
           }} name={"FBO - Сборка поставок"} className={"btn-info hover-elevate-up form-control mb-5"} />
        </div>
    );
};

export default Start;