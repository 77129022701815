import React from 'react';

const CardBody = (props) => {
    const {body} = props;
    return (
        <div className="card-body">
            {body}
        </div>
    );
};

export default CardBody;