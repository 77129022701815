import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {viewToastrMessage} from "../../utils/utils";
import {changeType} from "../../../store/reducers/StartMenuSlice";
import {Facebook} from "react-content-loader";
import ModalRangeInput from "../../modal/ModalRangeInput";
import {changeDataQty} from "../../../store/reducers/SendSelectedQtyFboSlice";
import {sendConfirmFbo} from "../../../store/reducers/ActionCreators";
import ConfirmOrderStore from "../../../store/reducers/SendConfirmOrderSlice";

const DetailFbo = () => {

    const dispatch = useDispatch();

    const {isLoading, detailData} = useSelector(state => state.SendSelectedQtyFbo);
    const {message} = useSelector(state => state.ConfirmOrderStore);
    const {valueDate, name} = useSelector(state => state.StartMenuStore);

    useEffect( () => {
        if (message.length) {
            for (let ind in message) {
                const sendMessage = message[ind];
                viewToastrMessage(sendMessage.message, 'Подтверждение сборки товара.', sendMessage.success);
            }
        };

        if (!detailData.orders.length) {
            setTimeout(() => dispatch(changeType('TYPE_FBO')), 1500);
        }

    }, [message] );

    async function sendDetailOrderToServer(ev, size, vendorCode, totalSelected, completed = false) {
        let marketplace = '';
        switch (name) {
            case "OZON":
                marketplace = 'ozon';
                break;
            case "WILDBERRIES":
                marketplace = 'wb';
                break;
            default:
                marketplace = '';
        }

        const sendData = {
            "data": [
                {
                    "supply_number": valueDate,
                    "marketplace": marketplace,
                    "products": [
                        {
                            "vendor_code": vendorCode,
                            "size": size,
                            "qty": totalSelected,
                            "completed": completed
                        }
                    ]
                }
            ]
        }
        dispatch(sendConfirmFbo(
            sendData,
            size,
            vendorCode,
            totalSelected,
            detailData));
    }

    function openModalSelectedRangeQty(ev, sizeName, sizeTotal, sizeVendorCode) {
        dispatch(changeDataQty(
            {
                size: sizeName,
                total: sizeTotal,
                vendorCode: sizeVendorCode
            }
        ))
    }

    let jsxTable = [];
    let textTitle = '';

    if (detailData.orders.length) {
        let ind = 0;
        let rowNumber = 0;
        for (const order of detailData.orders) {
            jsxTable.push(
                <tr key={ind++} className={'bg-secondary'}>
                    <th className={'text-center fw-bold fs-2hx'}>{++rowNumber}</th>
                    <th className={'text-center fw-bold fs-2hx'} colSpan={5}>{order.name}</th>
                </tr>
            )
            for (const size of order.sizes) {
                jsxTable.push(
                    <tr key={ind++} >
                        <td colSpan={2} className={'text-center fw-bold fs-2hx'}>
                            {size.name}
                        </td>
                        <td className={'text-center'}>
                            <a
                               onDoubleClick={(ev) => sendDetailOrderToServer(
                                   ev, size.name, size.detail.vendor_code, size.detail.total)}
                               className="btn btn-icon pulse pulse-danger form-control rounded-4 text-danger-emphasis btn-bg-success btn-sm fw-bold fs-3">
                                <i><span className="path1 fs-3hx text-center">{size.detail.total}</span></i>
                                <span className="pulse-ring border-5"></span>
                            </a>
                        </td>
                        <td className={'text-center fw-bold fs-2hx'}>
                            <a onClick={(ev) => {
                                openModalSelectedRangeQty(ev, size.name, size.detail.total, size.detail.vendor_code)
                            }} type="button" className="btn btn-bg-secondary form-control" data-bs-toggle="modal" data-bs-target="#range_qty">
                               {`${size.detail.balance} / ${size.detail.reserve}`}
                            </a>
                        </td>
                        <td>
                            <a onDoubleClick={(ev) => {
                                sendDetailOrderToServer(
                                    ev, size.name, size.detail.vendor_code, 0, true
                                )
                            }}
                               className="btn btn-bg-warning btn-color-danger form-control">
                                <i className="bi bi-check2-square fs-2x"></i>
                            </a>
                        </td>
                    </tr>
                )
            }
            textTitle = (
                <div className="alert alert-success align-items-center">
                    <div className="d-flex flex-column">
                        <span className={'fw-bold text-danger text-center'}>Количество не собранных товаров  {detailData.totalCountProducts}</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            { isLoading && <Facebook backgroundColor={'#ce0ecc'} title={'Загрузка данных'}/>}
            { !isLoading && textTitle}
            { !isLoading &&
                <div className="table-responsive">

                    <table className="table table-bordered border-dark">

                        <thead className={"bg-dark text-white fs-5"}>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Size</th>
                                <th className="text-center">Qty</th>
                                <th className="text-center">Balance / Reserve</th>
                                <th className="text-center">Completed</th>
                            </tr>
                        </thead>

                        <tbody>
                            {jsxTable}
                        </tbody>

                    </table>

                </div>
            }
            <ModalRangeInput />
        </>
    );
};

export default DetailFbo;