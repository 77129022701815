import {sizeData, vendorCodeData} from "../utils/utils";

export const returnDateDataFBS_Ozon = (data) => {
    const dateData = new Map();
    data.forEach((el) => {
        dateData.set(el.shipment_date, el.orders.length)
    })

    return dateData;
}


export const returnParentDataFBS_Ozon = (data, valueDate) => {
    const parentData = new Map();
    const dateData = data.filter((el) => el.shipment_date === valueDate );
    let totalCountOrders = 0;
    if (dateData.length) {
        totalCountOrders = dateData[0].orders.length;
        dateData[0].orders.forEach((el) => {
            el.products.forEach((elProduct) => {
                if (parentData.has(elProduct.parent)) {
                    parentData.get(elProduct.parent).total += elProduct.total;
                } else {
                    parentData.set(elProduct.parent, {total: elProduct.total})
                }
            })
        })
    }

    return {parentData, totalCountOrders};
}

export const returnTypeDataFBS_Ozon = (data, valueParent, valueDate) => {
    const typeData = new Map();
    let totalCountOrders = 0;
    const dateData = data.filter((el) => el.shipment_date === valueDate );

    if (dateData.length) {

        dateData[0].orders.forEach((el) => {
            const resultFilterProducts = el.products.filter((elProduct) =>
                valueParent === elProduct.parent
            )
            totalCountOrders += resultFilterProducts.length;
            resultFilterProducts.forEach((elProduct) => {
                if (typeData.has(elProduct.type)) {
                    typeData.get(elProduct.type).total += elProduct.total;
                } else {
                    typeData.set(elProduct.type, {total: elProduct.total})
                }
            })
        })

    }

    return {typeData, totalCountOrders};
}

export const returnDetailDataFBS_Ozon = (data, valueParent, valueType, valueDate) => {

    let totalCountOrders = 0;
    const orders = [];
    const dateData = data.filter((el) => el.shipment_date === valueDate );

    if (dateData.length) {

        dateData[0].orders.forEach((el) => {

            const resultFilterProducts = el.products.filter((elProduct) =>
                valueParent === elProduct.parent && valueType === elProduct.type
            )

            totalCountOrders += resultFilterProducts.length;

            resultFilterProducts.forEach((elProduct) => {

                let nameVendorCode = `${elProduct.vendor_code} - ( ${elProduct.code.replace(/^0+/, '')} )`

                const vendorCodeModel = vendorCodeData(orders, nameVendorCode);

                const sizeModel = sizeData(vendorCodeModel.sizes, elProduct.size);

                if (sizeModel.detail.total) {
                    sizeModel.detail.total += elProduct.total;
                } else {
                    sizeModel.detail = {
                        ...elProduct
                    }
                }
                sizeModel.orders.push({
                    number: el.order_number,
                    total: elProduct.total
                });

            })
        })
    }

    return {orders, totalCountOrders};
}
