
export const viewToastrMessage = (description, title, success = true) => {
    window.toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toastr-top-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    };
    if (success) {
        window.toastr.success(description, title);
    } else {
        window.toastr.error(description, title);
    }
}


export const vendorCodeData = (dataValue, vendorCode) => {
    for (let el of dataValue) {
        if (el.name === vendorCode) {
            return el;
        }
    }
    const newData = {name: vendorCode, sizes: []};
    dataValue.push(newData);
    return newData;
}

export const sizeData = (dataValue, size) => {
    for (let el of dataValue) {
        if (el.name === size) {
            return el;
        }
    }
    const newData = {name: size, orders: [], detail: {}};
    dataValue.push(newData);
    return newData;
}

export const selectStartFBO = (dataFBO, marketplaceName) => {

    let marketplace = '';

    switch (marketplaceName) {
        case "OZON":
            marketplace = 'ozon';
            break;
        case "WILDBERRIES":
            marketplace = 'wb';
            break;
        default:
            marketplace = 'wb';
    }

    const newFilterData = dataFBO.data.filter( el => el.marketplace === marketplace);

    const newData = [];

    let totalCountProducts = 0;

    newFilterData.forEach( el => {
        totalCountProducts += el.products.length;
        newData.push(
            {
                name: el.supply_number,
                total: el.products.length
            }
        )
    })

    return {newData, totalCountProducts};
}

export const selectSupplyDetailFBO = (dataFBO, marketplaceName, supplyNumber) => {
    const parentData = new Map();
    let marketplace = '';
    switch (marketplaceName) {
        case "OZON":
            marketplace = 'ozon';
            break;
        case "WILDBERRIES":
            marketplace = 'wb';
            break;
        default:
            marketplace = 'wb';
    }

    const newFilterData = dataFBO.data.filter( el => el.marketplace === marketplace && el.supply_number === supplyNumber);

    let totalCountProducts = 0;

    if (newFilterData.length) {
        newFilterData[0].products.forEach((elProduct) => {
            totalCountProducts += elProduct.qty;
            if (parentData.has(elProduct.parent)) {
                parentData.get(elProduct.parent).qty += elProduct.qty;
            } else {
                parentData.set(elProduct.parent, {qty: elProduct.qty})
            }
        })
    }

    return {parentData, totalCountProducts};
}

export const selectParentDetailFBO = (dataFBO, marketplaceName, supplyNumber, valueParent) => {
    const typeData = new Map();
    let totalCountProducts = 0;
    let marketplace = '';
    switch (marketplaceName) {
        case "OZON":
            marketplace = 'ozon';
            break;
        case "WILDBERRIES":
            marketplace = 'wb';
            break;
        default:
            marketplace = 'wb';
    }

    const newFilterData = dataFBO.data.filter( el => el.marketplace === marketplace && el.supply_number === supplyNumber);

    if (newFilterData.length) {

        const resultFilterProducts = newFilterData[0].products.filter((elProduct) =>
            valueParent === elProduct.parent
        )
        resultFilterProducts.forEach((elProduct) => {
            totalCountProducts += elProduct.qty;
            if (typeData.has(elProduct.type)) {
                typeData.get(elProduct.type).qty += elProduct.qty;
            } else {
                typeData.set(elProduct.type, {qty: elProduct.qty})
            }
        })
    }

    return {typeData, totalCountProducts};
}

export const selectTypeDetailFBO = (dataFBO, marketplaceName, supplyNumber, valueParent, valueType) => {

    let totalCountProducts = 0;
    const orders = [];
    let marketplace = '';
    switch (marketplaceName) {
        case "OZON":
            marketplace = 'ozon';
            break;
        case "WILDBERRIES":
            marketplace = 'wb';
            break;
        default:
            marketplace = 'wb';
    }

    const newFilterData = dataFBO.data.filter( el => el.marketplace === marketplace && el.supply_number === supplyNumber);

    if (newFilterData.length) {

        const resultFilterProducts = newFilterData[0].products.filter((elProduct) =>
            valueParent === elProduct.parent && valueType === elProduct.type
        )

        resultFilterProducts.forEach((elProduct) => {

            let nameVendorCode = `${elProduct.vendor_code} - ( ${elProduct.code.replace(/^0+/, '')} )`

            const vendorCodeModel = vendorCodeData(orders, nameVendorCode);

            const sizeModel = sizeData(vendorCodeModel.sizes, elProduct.size);
            totalCountProducts += elProduct.qty;
            if (sizeModel.detail.total) {
                sizeModel.detail.total += elProduct.qty;
            } else {
                sizeModel.detail = {
                    ...elProduct,
                    total: elProduct.qty
                }
            }
        })

    }

    return {orders, totalCountProducts};
}
