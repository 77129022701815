import {createSlice} from '@reduxjs/toolkit'

const initialStateDetailData = {
    totalCountOrders: 0,
    orders: {}
}


const initialState = {
    message: [],
    isLoading: false,
    detailData: initialStateDetailData
}

export const SendConfirmOrderSlice = createSlice({
    name: 'confirmOrder',
    initialState,
    reducers : {
        confirm(state) {
            state.isLoading = true;
            state.message = [];
        },
        changeDetailData(state, action) {
            state.detailData = action.payload;
            state.isLoading = false;
        },
        confirmSuccess(state) {
            state.isLoading = false;
        },
        changeMessages(state, action) {
            state.message = action.payload;
        }
    }
})

export default SendConfirmOrderSlice.reducer;
export const {confirm, changeMessages, changeDetailData, confirmSuccess} = SendConfirmOrderSlice.actions;
