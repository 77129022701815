import React from 'react';
import {nanoid} from "@reduxjs/toolkit";

const Button = (props) => {
    const {className, onClick, name} = props;
    return (
        <a key={nanoid()}
            onClick={(ev) => onClick(ev)}
            className={"btn " + className}
            name={name}
        >{name}</a>
    );
};

export default Button;