import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOrdersFBS} from "../../../store/reducers/ActionCreators";
import {changeType} from "../../../store/reducers/StartMenuSlice";
import LinkButton from "../../buttons/LinkButton";
import {returnDetailDataFBS_Ozon, returnTypeDataFBS_Ozon} from "../settings";
import {changeDetailData, changeMessages} from "../../../store/reducers/SendConfirmOrderSlice";

const Type = () => {
    const dispatch = useDispatch();
    const {valueParent, valueDate, data} = useSelector(state => state.StartMenuStore);

    let jsxTable = [];

    useEffect(() => {
        dispatch(getOrdersFBS('ozon'));
    }, [])

    const onClickType = (nameType) => {
        dispatch(changeType('DETAIL'));
        const {orders, totalCountOrders} = returnDetailDataFBS_Ozon(
            data.data, valueParent, nameType, valueDate);
        dispatch(changeDetailData({totalCountOrders, orders}))
        dispatch(changeMessages([]));
    }

    let textTitle = '';
    if (data) {
        const {typeData, totalCountOrders} = returnTypeDataFBS_Ozon(data.data, valueParent, valueDate);
        let ind = 0;
        for (let [type, value] of typeData.entries()) {
            jsxTable.push(
                (
                    <tr key={ind}>
                        <td className={"text-center fs-3"}>{++ind}</td>
                        <td className={"text-center"}><LinkButton name={type} onClick={onClickType} /></td>
                        <td className={"text-center fw-bold fs-3"}>{value.total}</td>
                    </tr>
                )
            )
        }
        textTitle = (
            <div className="alert alert-success align-items-center">
                <div className="d-flex flex-column">
                    <h6 className="mb-1 text-dark text-center">Сборка заказов FBS</h6>
                    <h4 className="mb-1 text-info text-center">{valueDate}</h4>
                    <h4 className="mb-1 text-info text-center">{valueParent}</h4>
                    <p className={'text-danger text-center'}>Количество не собранных заказов
                        <span className={'fw-bold'}>  {totalCountOrders}</span>
                    </p>
                </div>
            </div>
        );
    }
    return (
        <>
            {textTitle}
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr className="fw-bold fs-6 text-gray-800">
                            <th className={"text-center"}>#</th>
                            <th className={"text-center"}>Type</th>
                            <th className={"text-center"}>Quantity</th>
                        </tr>
                    </thead>

                    <tbody>
                        {jsxTable}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Type;