import React, {useEffect} from 'react';
import {getOrdersFBS} from "../../../store/reducers/ActionCreators";
import {useDispatch, useSelector} from "react-redux";
import {changeType, changeValueParent} from "../../../store/reducers/StartMenuSlice";
import LinkButton from "../../buttons/LinkButton";
import {Facebook} from "react-content-loader";
import {returnParentDataFBS_Ozon} from "../settings";

const Parent = () => {

    const dispatch = useDispatch();
    const {data, valueDate} = useSelector(state => state.StartMenuStore)

    useEffect(() => {
        dispatch(getOrdersFBS('ozon'));
    }, [])
    let textTitle = '';
    let jsxTable = [];
    const onClickParent = (nameParent) => {
        dispatch(changeType('TYPE'));
        dispatch(changeValueParent(nameParent));
    }

    if (data) {
        const {parentData, totalCountOrders} = returnParentDataFBS_Ozon(data.data, valueDate);
        let ind = 0;

        for (let [parent, value] of parentData.entries()) {
            jsxTable.push(
                (
                    <tr key={ind}>
                        <td className={"text-center fs-3"}>{++ind}</td>
                        <td className={"text-center"}><LinkButton name={parent} onClick={onClickParent} /></td>
                        <td className={"text-center fw-bold fs-3"}>{value.total}</td>
                    </tr>
                )
            )
        }
        textTitle = (
            <div className="alert alert-success align-items-center">
                <div className="d-flex flex-column">
                    <h4 className="mb-1 text-dark text-center">Сборка заказов FBS</h4>
                    <h4 className="mb-1 text-info text-center">{valueDate}</h4>
                    <p className={'text-danger text-center'}>Количество не собранных заказов
                        <span className={'fw-bold'}>  {totalCountOrders}</span>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            {!data && <Facebook />}
            {data && textTitle}
            {data &&
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th className={"text-center"}>#</th>
                                <th className={"text-center"}>Name</th>
                                <th className={"text-center"}>Quantity</th>
                            </tr>
                        </thead>

                        <tbody>
                            {jsxTable}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default Parent;