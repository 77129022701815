import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    name: '',
    type: '',
    valueType: '',
    valueParent: '',
    valueDate: '',
    data: undefined,
    dataFBO: undefined
}

export const StartMenuSlice = createSlice({
    name: 'startMenu',
    initialState,
    reducers: {
        changeName(state, action) {
            state.name = action.payload;
            if (!state.name) {
                state.type = '';
                state.valueDate = '';
                state.valueParent = '';
                state.data = undefined;
                state.dataFBO = undefined;
            }
        },
        changeType(state, action) {
            state.type = action.payload;
        },
        changeValueDate(state, action) {
            state.valueDate = action.payload;
        },
        changeValueType(state, action) {
            state.valueType = action.payload;
        },
        changeValueParent(state, action) {
            state.valueParent = action.payload;
            state.valueType = '';
        },
        changeData(state, action) {
            state.data = action.payload;
            state.dataFBO = undefined;
        },
        changeDataFBO(state, action) {
            state.dataFBO = action.payload;
            state.data = undefined;
        }
    }
})

export default StartMenuSlice.reducer;

export const {
    changeData,
    changeDataFBO,
    changeValueDate,
    changeName,
    changeValueParent,
    changeType,
    changeValueType} = StartMenuSlice.actions;