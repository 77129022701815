import {getUser} from "../../store/reducers/ActionCreators";
import {useDispatch} from "react-redux";
import IndicatorButton from "../buttons/IndicatorBuuton";

const SignIn = (props) => {

    const dispatch = useDispatch();
    const onClickButton = () => {

        const userIdEl = document.querySelector('#chat-id');
        const userId = userIdEl.value || '';

        if (!userId) {
            return
        }

        localStorage.setItem('userId', userId);

        const button = document.querySelector("#sign-in-button");

        if (!button) {
            return;
        }

        button.setAttribute("data-kt-indicator", "on");
        setTimeout(function() {
                button.removeAttribute("data-kt-indicator");
            }, 3000);

        dispatch(getUser())

    }

    return (
        <div className="d-flex flex-column flex-column-fluid flex-lg-row flex-center">

            <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">

                <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">

                    <form className="form w-100 mb-13" id="kt_sing_in_two_factor_form">

                        <div className="text-center mb-10">

                            <h1 className="text-dark mb-3">Авторизация пользователя в системе</h1>

                        </div>

                        <div className="mb-10">

                            <div className="input-group mb-5">
                                <span className="input-group-text" id="basic-addon3">Chat ID Telegram</span>
                                <input type="text" className="form-control" id="chat-id"
                                       aria-describedby="basic-addon3"/>
                            </div>

                        </div>

                        <div className="d-flex flex-center">
                            <IndicatorButton
                                name={"Войти"}
                                onClick={onClickButton}
                                id={'sign-in-button'}/>
                        </div>

                    </form>

                </div>

            </div>

        </div>
    );
};

export default SignIn;