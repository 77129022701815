import React, {useEffect} from 'react';
import {getDataFBO} from "../../../store/reducers/ActionCreators";
import {useDispatch, useSelector} from "react-redux";
import LinkButton from "../../buttons/LinkButton";
import {changeType, changeValueParent} from "../../../store/reducers/StartMenuSlice";
import {selectSupplyDetailFBO} from "../../utils/utils";
import {Facebook} from "react-content-loader";

const ParentFBO = () => {
    const {name, dataFBO, valueDate} = useSelector(state => state.StartMenuStore);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDataFBO());
    }, [])
    let textTitle = '';
    let jsxTable = [];

    const onClickParent = (nameParent) => {
        dispatch(changeType('TYPE_FBO'));
        dispatch(changeValueParent(nameParent));
    }

    if (dataFBO) {
        const {parentData, totalCountProducts} = selectSupplyDetailFBO(dataFBO, name, valueDate);
        let ind = 0;

        for (let [parent, value] of parentData.entries()) {
            jsxTable.push(
                (
                    <tr key={ind}>
                        <td className={"text-center fs-3"}>{++ind}</td>
                        <td className={"text-center"}><LinkButton name={parent} onClick={onClickParent} /></td>
                        <td className={"text-center fw-bold fs-3"}>{value.qty}</td>
                    </tr>
                )
            )
        }
        textTitle = (
            <div className="alert alert-success align-items-center">
                <div className="d-flex flex-column">
                    <h4 className="mb-1 text-dark text-center">Сборка поставок FBO</h4>
                    <h4 className="mb-1 text-info text-center">{valueDate}</h4>
                    <p className={'text-danger text-center'}>Количество не собранных товаров
                        <span className={'fw-bold'}>  {totalCountProducts}</span>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            {!dataFBO && <Facebook />}
            {dataFBO && textTitle}
            {dataFBO &&
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th className={"text-center"}>#</th>
                                <th className={"text-center"}>Name</th>
                                <th className={"text-center"}>Quantity</th>
                            </tr>
                        </thead>

                        <tbody>
                            {jsxTable}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default ParentFBO;