import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeName, changeType} from "../../store/reducers/StartMenuSlice";
import CardTitle from "../startPage/card/CardTitle";
import CardBody from "../startPage/card/CardBody";
import CardFooter from "../startPage/card/CardFooter";
import Start from "./steps/Start";
import Date from "./steps/Date";
import Parent from "./steps/Parent";
import Type from "./steps/Type";
import Detail from "./steps/Detail";
import StartFBO from "../marketplaceFBO/steps/StartFBO";
import ParentFBO from "../marketplaceFBO/steps/ParentFBO";
import TypeFbo from "../marketplaceFBO/steps/TypeFBO";
import DetailFbo from "../marketplaceFBO/steps/DetailFBO";

const StartPageOzon = () => {
    const {type, name, title} = useSelector(state => state.StartMenuStore);
    let body;
    const dispatch = useDispatch();
    let nameReturn = '';
    const returnHome = () => {
        if (!nameReturn) {
            dispatch(changeName(nameReturn));
        } else {
            dispatch(changeType(nameReturn));
        }
    }

    let titleCard = name;
    if (title) {
        titleCard = `
                <p>${name}</p>
                <br/>
                ${title}
        `
    }

    switch (type) {
        case "START":
            body = <Start />;
            nameReturn = '';
            break
        case "DATE":
            body = <Date />;
            nameReturn = 'START';
            break;
        case "FBO":
            body = <StartFBO />;
            nameReturn = 'START';
            break;
        case "FBO_SUPPLY":
            body = <ParentFBO />;
            nameReturn = 'FBO';
            break;
        case "TYPE_FBO":
            body = <TypeFbo />;
            nameReturn = 'FBO_SUPPLY';
            break;
        case "DETAIL_FBO":
            body = <DetailFbo />;
            nameReturn = 'TYPE_FBO';
            break;
        case "PARENT":
            body = <Parent />;
            nameReturn = 'DATE';
            break;
        case "TYPE":
            body = <Type />;
            nameReturn = 'PARENT';
            break;
        case "DETAIL":
            body = <Detail />;
            nameReturn = 'TYPE';
            break
        default:
            body = <Start />;
            nameReturn = '';
            break
    }
    return (
        <>
            <CardTitle title={titleCard} />
            <CardBody body={body} />
            <CardFooter name={'Назад'} onClickButton={returnHome} className={'btn-warning hover-scale form-control'}/>
        </>
    )
};

export default StartPageOzon;