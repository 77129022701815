import {combineReducers, configureStore} from "@reduxjs/toolkit";
import UserStore from './reducers/UserSlice'
import StartMenuStore from './reducers/StartMenuSlice'
import ConfirmOrderStore from './reducers/SendConfirmOrderSlice'
import SendSelectedQtyFbo from "./reducers/SendSelectedQtyFboSlice";
import SupplierOrderStore from "./reducers/SupplierOrderSlice";

const rootReducer = combineReducers({
    UserStore,
    StartMenuStore,
    ConfirmOrderStore,
    SendSelectedQtyFbo,
    SupplierOrderStore
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}
