import React, {useEffect} from 'react';
import {Facebook} from "react-content-loader";
import ModalSupplierOrder from "./modal/ModalSupplierOrder";
import {useDispatch} from "react-redux";
import {changeDataSupplier} from "../store/reducers/SupplierOrderSlice";

const DetailTable = (props) => {

    const {isLoading, detailData, sendDetailOrderToServer} = props;
    const dispatch = useDispatch();

    let jsxTable = [];
    let textTitle = '';

    function changeProductSupplier(product) {
        dispatch(changeDataSupplier(product));
    }

    if (detailData.orders.length) {
        let ind = 0;
        let rowNumber = 0;
        for (const order of detailData.orders) {
            jsxTable.push(
                <tr key={ind++} className={'bg-secondary'}>
                    <th className={'text-center fw-bold fs-2hx'}>{++rowNumber}</th>
                    <th className={'text-center fw-bold fs-2hx'} colSpan={4}>{order.name}</th>
                </tr>
            )
            for (const size of order.sizes) {
                jsxTable.push(
                    <tr key={ind++} >
                        <td colSpan={2} className={"text-center"}>
                            <a
                               onDoubleClick={(ev) => sendDetailOrderToServer(
                                   ev, {size: size.name, collected: 1, vendor_code: size.detail.vendor_code})}
                               className="btn btn-icon form-control pulse pulse-primary rounded-4 text-danger btn-bg-secondary btn-sm fw-bold fs-3">
                                <i><span className="path1 text-center fs-3hx">1</span></i>
                                <span className="pulse-ring border-5"></span>
                            </a>
                        </td>
                        <td className={'text-center fw-bold fs-2hx'}>
                            {size.name}
                        </td>
                        <td className={'text-center'}>
                            <a
                               onDoubleClick={(ev) => sendDetailOrderToServer(
                                   ev, {size: size.name, collected: size.detail.total, vendor_code: size.detail.vendor_code})}
                               className="btn btn-icon pulse pulse-danger form-control rounded-4 text-danger-emphasis btn-bg-success btn-sm fw-bold fs-3">
                                <i><span className="path1 fs-3hx text-center">{size.detail.total}</span></i>
                                <span className="pulse-ring border-5"></span>
                            </a>
                        </td>
                        <td>
                            <a onClick={() => {changeProductSupplier(size.detail)}}
                               className="btn form-control rounded-4 text-bg-primary btn-sm fw-bold fs-3"
                               data-bs-toggle="modal"
                               data-bs-target="#supplier_order">
                                {`${size.detail.balance} / ${size.detail.ordered}`}
                            </a>
                        </td>
                    </tr>
                )
            }
            textTitle = (
                <div className="alert alert-success align-items-center">
                    <div className="d-flex flex-column">
                        {/*<h6 className="mb-1 text-dark text-center">Сборка заказов FBS</h6>*/}
                        {/*<h4 className="mb-1 text-info text-center">{valueDate}</h4>*/}
                        {/*<h4 className="mb-1 text-info text-center">{valueParent}</h4>*/}
                        {/*<h4 className="mb-1 text-info text-center">{valueType}</h4>*/}
                        {/*<p className={'text-danger text-center'}>Количество не собранных заказов*/}
                        <span className={'fw-bold text-danger text-center'}>Количество не собранных заказов  {detailData.totalCountOrders}</span>
                        {/*</p>*/}
                    </div>
                </div>
            )
        }
    }
    return (
        <>
            { isLoading && <Facebook backgroundColor={'#ce0ecc'} title={'Загрузка данных'}/>}
            { !isLoading && textTitle}
            { !isLoading &&
                <>
                    <div className="table-responsive">

                        <table className="table table-bordered border-dark">

                            <thead className={"bg-dark text-white fs-5"}>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th className="text-center">Code</th>
                                    <th className="text-center">Size</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Stock / Ordered</th>
                                </tr>
                            </thead>

                            <tbody>
                                {jsxTable}
                            </tbody>

                        </table>

                    </div>
                    <ModalSupplierOrder />
                </>
            }
        </>
    );
};

export default DetailTable;