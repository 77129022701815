import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    isLoading: false,
    size: '',
    vendorCode: '',
    total: 0,
    detailData: undefined
}

export const SendSelectedQtyFboSlice = createSlice({
    name: 'confirmOrderFbo',
    initialState,
    reducers : {
        confirmQty(state) {
            state.isLoading = true;
        },
        changeDetailDataQty(state, action) {
            state.detailData = action.payload;
            state.isLoading = false;
        },
        changeDataQty(state, action) {
            state.isLoading = false;
            state.size = action.payload.size;
            state.vendorCode = action.payload.vendorCode;
            state.total = action.payload.total;
        },
        confirmSuccessQty(state, action) {
            state.isLoading = false;
        }
    }
})

export default SendSelectedQtyFboSlice.reducer;
export const {confirmQty, changeDetailDataQty, changeDataQty, confirmSuccessQty} = SendSelectedQtyFboSlice.actions;
