import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import './slider.css'
import {sendConfirmFbo} from "../../store/reducers/ActionCreators";

const ModalRangeInput = () => {

    const dispatch = useDispatch();
    const {size, vendorCode, total, detailData} = useSelector(state => state.SendSelectedQtyFbo);
    const {valueDate, name} = useSelector(state => state.StartMenuStore);

    useEffect(() => {

        const budgetSlider = document.querySelector("#slider-round");
        const budgetValue = document.querySelector("#kt_docs_forms_advanced_interactive_slider_label");

        noUiSlider.create(budgetSlider, {
            start: [1],
            connect: true,
            step: 1,
            range: {
                "min": 1,
                "max": total
            }
        });

        budgetSlider.noUiSlider.on("update", function (values, handle) {
            budgetValue.innerHTML = Math.round(values[handle]);
            budgetValue.setAttribute('value', Math.round(values[handle]));
            if (handle) {
                budgetValue.innerHTML = Math.round(values[handle]);
            }
        });

        return () => {
            budgetSlider.noUiSlider.destroy();
        }

    }, [size, vendorCode, total])

    function sendSelectedQtyToServer(ev) {
        const elValue = document.querySelector('#kt_docs_forms_advanced_interactive_slider_label');
        const totalSelected = parseInt(elValue.getAttribute('value'));
        let marketplace = '';

        switch (name) {
            case "OZON":
                marketplace = 'ozon';
                break;
            case "WILDBERRIES":
                marketplace = 'wb';
                break;
            default:
                marketplace = '';
        }

        const sendData = {
            "data": [
                {
                    "supply_number": valueDate,
                    "marketplace": marketplace,
                    "products": [
                        {
                            "vendor_code": vendorCode,
                            "size": size,
                            "qty": totalSelected,
                            "completed": false
                        }
                    ]
                }
            ]
        }
        dispatch(sendConfirmFbo(
            sendData,
            size,
            vendorCode,
            totalSelected,
            detailData));
    }

    return (
        <div className="modal fade" tabIndex="-1" id="range_qty">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h3 className="modal-title">Укажите кол-во собранного товара</h3>

                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                             aria-label="Close">
                            <i className="ki-duotone ki-cross fs-1"><span className="path1"></span><span
                                className="path2"></span></i>
                        </div>

                    </div>

                    <div className="modal-body">

                        <div className="mb-0">
                            <label className="fs-4 fw-medium mb-4">
                                <p>Размер: {size}</p>
                                <p>Артикул: {vendorCode}</p>
                            </label>

                            <div className="d-flex flex-column ">
                                <div className="d-flex form-control mb-7">
                                    <span className="fw-bold fs-4 mt-1 me-2">PCS</span>
                                    <span className="fw-bold fs-3x"
                                          id="kt_docs_forms_advanced_interactive_slider_label"></span>
                                    <span className="fw-bold fs-3x">.00</span>
                                </div>

                                <div id="slider-round" className="slider-styled"></div>

                            </div>

                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Закрыть</button>
                        <button onClick={sendSelectedQtyToServer} type="button" className="btn btn-primary"
                                data-bs-dismiss="modal">Подтвердить
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ModalRangeInput;