
const IndicatorButton = (props) => {
    const {name, id, onClick} = props;

    return (
        <button type="button" id={id}
                onClick={() => onClick()}
                className="btn btn-lg btn-primary fw-bold">
            <span className="indicator-label">{name}</span>
            <span className="indicator-progress">Пожалуйста ожидайте...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
        </button>
    );
};

export default IndicatorButton;