import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOrdersFBS} from "../../../store/reducers/ActionCreators";
import {Facebook} from "react-content-loader";
import Button from "../../buttons/Button";
import {changeType, changeValueDate} from "../../../store/reducers/StartMenuSlice";
import {returnDateDataFBS_Ozon} from "../settings";
import {nanoid} from "@reduxjs/toolkit";

const Date = () => {

    const dispatch = useDispatch();
    const {data} = useSelector(state => state.StartMenuStore)

    useEffect(() => {
        dispatch(getOrdersFBS('ozon'));
    }, [])
    let textTitle = '';

    function onCLickDate(ev, orderDate) {
        dispatch(changeType('PARENT'));
        dispatch(changeValueDate(orderDate));
    }
    const jsxButtonsDate = [];
    if (data) {
        const dateData = returnDateDataFBS_Ozon(data.data);
        let totalCountOrders = 0;
        for (let [orderDate, countOrders] of dateData.entries()) {
            totalCountOrders += countOrders;
            jsxButtonsDate.push(
                <div key={nanoid()}>
                   <Button onClick={(ev) => {
                        onCLickDate(ev, orderDate);
                   }} name={`${orderDate}  - [ ${countOrders} ]`} className={"btn-primary hover-elevate-up form-control mb-5"} />
                </div>
            )
        }

        textTitle = (
            <div key={nanoid()} className="alert alert-success align-items-center">
                <div className="d-flex flex-column">
                    <h4 className="mb-1 text-dark text-center">Сборка заказов FBS</h4>
                    <p className={'text-danger text-center'}>Количество не собранных заказов
                        <span className={'fw-bold'}>  {totalCountOrders}</span>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            {!data && <Facebook />}
            {data && textTitle}
            {data && jsxButtonsDate}
        </>
    );
};

export default Date;