import {sizeData, vendorCodeData} from "../utils/utils";

export const returnParentDataFBS_WB = (data) => {
    const parentData = new Map();
    data.forEach((el) => {
        el.products.forEach((elProduct) => {
            if (parentData.has(elProduct.parent)) {
                parentData.get(elProduct.parent).total += elProduct.total;
            } else {
                parentData.set(elProduct.parent, {total: elProduct.total})
            }
        })
    })

    return parentData;
}

export const returnTypeDataFBS_WB = (data, valueParent) => {
    const typeData = new Map();
    let totalCountOrders = 0;

    data.forEach((el) => {
        const resultFilterProducts = el.products.filter((elProduct) =>
            valueParent === elProduct.parent
        )
        totalCountOrders += resultFilterProducts.length;
        resultFilterProducts.forEach((elProduct) => {
            if (typeData.has(elProduct.type)) {
                typeData.get(elProduct.type).total += elProduct.total;
            } else {
                typeData.set(elProduct.type, {total: elProduct.total})
            }
        })
    })

    return {typeData, totalCountOrders};
}

export const returnDetailDataFBS_WB = (data, valueParent, valueType) => {

    const orders = [];
    let totalCountOrders = 0;

    data.forEach((el) => {

        const resultFilterProducts = el.products.filter((elProduct) =>
            valueParent === elProduct.parent && valueType === elProduct.type
        )

        totalCountOrders += resultFilterProducts.length;

        resultFilterProducts.forEach((elProduct) => {

            let nameVendorCode = `${elProduct.vendor_code} - ( ${elProduct.code.replace(/^0+/, '')} )`

            const vendorCodeModel = vendorCodeData(orders, nameVendorCode);

            const sizeModel = sizeData(vendorCodeModel.sizes, elProduct.size);

            if (sizeModel.detail.total) {
                sizeModel.detail.total += elProduct.total;
            } else {
                sizeModel.detail = {
                    ...elProduct
                }
            }
            sizeModel.orders.push({
                number: el.order_number,
                total: elProduct.total
            });

        })
    })

    return {orders, totalCountOrders};
}