import React from 'react';
import Button from "../../buttons/Button";

const CardFooter = (props) => {
    const {name, onClickButton, className} = props;
    return (
        <div className="card-footer">
            <div className="separator separator-dotted border-success my-5"></div>
            <Button onClick={onClickButton} name={name} className={className} />
        </div>
    );
};

export default CardFooter;