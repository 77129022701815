import React from 'react';

const LinkButton = (props) => {
    const {name, onClick} = props;
    return (
        <a href="#"
           onClick={(ev) => onClick(name)}
           className="text-primary-emphasis fw-bold fs-1">{name}</a>
    );
};

export default LinkButton;