import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createSupplierOrder, getAllProductsByVendorCode} from "../../store/reducers/ActionCreators";
import './slider.css'
import {nanoid} from "@reduxjs/toolkit";

const ModalSupplierOrder = () => {

    const {product, detailData} = useSelector(state => state.SupplierOrderStore);
    const {size, balance, ordered, sales_total, quantity_day, vendor_code} = product;
    const {userId} = useSelector(state => state.UserStore);
    const dispatch = useDispatch();

    useEffect( () => {
        if (vendor_code && size && !detailData) {
            dispatch(getAllProductsByVendorCode(vendor_code));
        }
    }, [product, detailData])

    function sendSupplierOrder() {
        const valueRangeInputs = document.querySelectorAll('.selected_qty');
        const items = [];
        valueRangeInputs.forEach( el => {
            let total = parseInt(el.getAttribute('value'));
            let size = el.getAttribute('size-product');
            if (total) {
                items.push({
                    size,
                    total
                })
            }
        } );

        if (items.length) {
            const sendData =
                {
                    "chat_id": parseInt(userId),
                    "vendor_code": vendor_code,
                    items
                }
            dispatch(createSupplierOrder(sendData));
        }

    }

    function changeValueInput(ev) {
        const elValue = document.getElementById(`value${ev.target.id.replace("inputValue", "")}`);
        elValue.innerText = ev.target.value;
        elValue.setAttribute("value", ev.target.value);
    }

    function addRowInTable(ind, sizeRow, balanceRow, salesTotalRow, orderedRow, quantityDayRow) {
            jsxTable.push(
                    <tr className={"text-center fs-2"} key={nanoid()}>
                        <th>{sizeRow}</th>
                        <th>{balanceRow}</th>
                        <th>{salesTotalRow}</th>
                        <th>{orderedRow}</th>
                        <th>{quantityDayRow}</th>
                        <th id={`value${ind}`} size-product={sizeRow} className={'selected_qty'} value={'0'}>
                            0
                        </th>
                    </tr>);
            jsxTable.push(
                    <tr key={nanoid()}>
                        <td colSpan="6">
                            <input style={style} id={`inputValue${ind}`}
                                   onInput={changeValueInput}
                                   min="0"
                                   size-product={sizeRow}
                                   max="500"
                                   type="range"
                                   step="5"
                                   defaultValue={"0"}
                                   className="sliderRange form-control"/>
                        </td>
                    </tr>
            )
    }

    const style = {
        background: '#25b076'
    }
    let jsxTable = [];
    if (detailData) {
        detailData.data.forEach( (el, ind) => {
            addRowInTable(ind, el.size, el.balance, el.sales_total, el.ordered, el.quantity_day);
        } )
    } else {
        if (!size) {
            addRowInTable(0, size, balance, sales_total, ordered, quantity_day);
        }
    }

    return (
        <div className="modal fade" tabIndex="-1" id="supplier_order">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h3 className="modal-title">Заказать {vendor_code}</h3>

                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                             aria-label="Close">
                            <i className="ki-duotone ki-cross fs-1"><span className="path1"></span><span
                                className="path2"></span></i>
                        </div>

                    </div>

                    <div className="modal-body">

                        <div className="table-responsive">

                            <table className="table table-bordered border-dark">

                                <thead className={"bg-dark text-white fs-5"}>
                                    <tr>
                                        <th className="text-center">Size</th>
                                        <th className="text-center">Stock</th>
                                        <th className="text-center">Sales</th>
                                        <th className="text-center">Ordered</th>
                                        <th className="text-center">Qty day</th>
                                        <th className="text-center">To order</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {jsxTable}
                                </tbody>

                            </table>

                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Закрыть</button>
                        <button onClick={sendSupplierOrder} type="button" className="btn btn-primary"
                                data-bs-dismiss="modal">Создать заказ поставщику
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ModalSupplierOrder;