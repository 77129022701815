import React from 'react';
import CardTitle from "./card/CardTitle";
import CardBody from "./card/CardBody";
import StartButtons from "./StartButtons";
import {useSelector} from "react-redux";
import StartPage from "../wilberries/StartPage";
import StartPageOzon from "../ozon/StartPageOzon";
import StartPageKaspi from "../kaspi/StartPageKaspi";

const StartPageMenu = () => {
    const {name} = useSelector(state => state.StartMenuStore);
    let card;

    switch (name) {
        case "WILDBERRIES":
            card = <StartPage />;
            break;
        case "OZON":
            card = <StartPageOzon />;
            break
        case "KASPI":
            card = <StartPageKaspi />;
            break
        default:
            card = (
                <>
                    <CardTitle title={"Lucente MSK"} />
                    <CardBody body={<StartButtons />} />
                </>
            )

    }
    const classStyle = {marginLeft: 10, marginRight: 10}
    return (
        <div style={classStyle} className={"border-dashed mb-4"}>
            {card}
        </div>
    );
};

export default StartPageMenu;