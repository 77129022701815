import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {viewToastrMessage} from "../../utils/utils";
import {changeType} from "../../../store/reducers/StartMenuSlice";
import {sendConfirmOrder} from "../../../store/reducers/ActionCreators";
import DetailTable from "../../DetailTable";

const Detail = () => {

    const dispatch = useDispatch();

    const {isLoading, detailData, message} = useSelector(state => state.ConfirmOrderStore);

    useEffect( () => {
        if (message.length) {
            for (let ind in message) {
                const sendMessage = message[ind];
                viewToastrMessage(sendMessage.message, 'Подтверждение сборки товара.', sendMessage.success);
            }
        };

        if (!detailData.orders.length) {
            setTimeout(() => dispatch(changeType('TYPE')), 1500);
        }

    }, [message] );


    async function sendDetailOrderToServer(ev, sendData) {
        sendData.marketplace = 'kaspi';
        dispatch(sendConfirmOrder(sendData, detailData));
    }

    return (
            <DetailTable isLoading={isLoading}
                         detailData={detailData}
                         sendDetailOrderToServer={sendDetailOrderToServer} />
    );
};

export default Detail;