import React from 'react';

const CardTitle = (props) => {
    const {title} = props;

    return (
        <div className="card-header">
            <div id={'card-title'} className="border-gray-800 text-danger border-dotted text-center form-control mb-7">
                {title}
            </div>
        </div>
    );
};

export default CardTitle;