import React, {useEffect} from 'react';
import {Facebook} from "react-content-loader";
import {useDispatch, useSelector} from "react-redux";
import {getDataFBO} from "../../../store/reducers/ActionCreators";
import {selectStartFBO} from "../../utils/utils";
import {nanoid} from "@reduxjs/toolkit";
import Button from "../../buttons/Button";
import {changeType, changeValueDate} from "../../../store/reducers/StartMenuSlice";

const StartFBO = () => {
    const {name, dataFBO} = useSelector(state => state.StartMenuStore);
    const dispatch = useDispatch();
    let textTitle = '';
    let jsxButtonsSupply = [];
    useEffect(() => {
        dispatch(getDataFBO());
    }, [])
    function onCLickSupply(ev, supplyNumber) {
        dispatch(changeType('FBO_SUPPLY'));
        dispatch(changeValueDate(supplyNumber));
    }
    if (dataFBO) {
        const {newData, totalCountProducts} = selectStartFBO(dataFBO, name);

        for (const supply of newData) {
            jsxButtonsSupply.push(
                <div key={nanoid()}>
                   <Button
                       onClick={(ev) => {onCLickSupply(ev, supply.name)}}
                       name={`${supply.name}  - [ ${supply.total} ]`}
                       className={"btn-bg-success hover-elevate-up form-control mb-5"}
                   />
                </div>
            )
        }
        textTitle = (
            <div key={nanoid()} className="alert alert-success align-items-center">
                <div className="d-flex flex-column">
                    <h4 className="mb-1 text-dark text-center">Сборка поставок FBO</h4>
                    <p className={'text-danger text-center'}>Количество не собранных карточек товаров
                        <span className={'fw-bold'}>  {totalCountProducts}</span>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            {!dataFBO && <Facebook />}
            {dataFBO && textTitle}
            {dataFBO && jsxButtonsSupply}
        </>
    );
};

export default StartFBO;