import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import SignIn from "./components/signIn/SignIn";
import {getUser} from "./store/reducers/ActionCreators";
import StartPageMenu from "./components/startPage/StartPageMenu";

function App() {

  const {userId} = useSelector(state => state.UserStore);
  const dispatch = useDispatch();
  
  useEffect(() => {
    window.Telegram.WebApp.ready();
    dispatch(getUser());
  }, [])

  return (
      <>
        { !userId &&  <SignIn/> }
        { userId && <StartPageMenu /> }
      </>
  );
}

export default App;
