import React from 'react';
import Button from "../buttons/Button";
import {useDispatch} from "react-redux";
import {changeName, changeType} from "../../store/reducers/StartMenuSlice";

const StartButtons = () => {

    const dispatch = useDispatch();
    function onClickButton(ev) {
        const nameButton = ev.target.name;
        dispatch(changeName(nameButton));
        dispatch(changeType('START'));
    }

    return (
        <>
            <Button onClick={onClickButton} name={"WILDBERRIES"} className={"btn-info hover-scale form-control mb-5"} />

            <Button onClick={onClickButton} name={"OZON"} className={"btn-primary hover-scale form-control mb-5"} />

            <Button onClick={onClickButton} name={"KASPI"} className={"btn-danger hover-scale form-control"} />
        </>
    );
};

export default StartButtons;