import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Start from "./steps/Start";
import CardTitle from "../startPage/card/CardTitle";
import CardBody from "../startPage/card/CardBody";
import CardFooter from "../startPage/card/CardFooter";
import {changeName, changeType} from "../../store/reducers/StartMenuSlice";
import Parent from "./steps/Parent";
import Type from "./steps/Type";
import Detail from "./steps/Detail";

const StartPageKaspi = () => {
    const {type, name, title} = useSelector(state => state.StartMenuStore);
    let body;
    const dispatch = useDispatch();
    let nameReturn = '';
    const returnHome = () => {
        if (!nameReturn) {
            dispatch(changeName(nameReturn));
        } else {
            dispatch(changeType(nameReturn));
        }
    }

    let titleCard = name;
    if (title) {
        titleCard = `
                <p>${name}</p>
                <br/>
                ${title}
        `
    }

    switch (type) {
        case "START":
            body = <Start />;
            nameReturn = '';
            break
        case "PARENT":
            body = <Parent />;
            nameReturn = 'START';
            break;
        case "TYPE":
            body = <Type />;
            nameReturn = 'PARENT';
            break;
        case "DETAIL":
            body = <Detail />;
            nameReturn = 'TYPE';
            break
        default:
            body = <Start />;
            nameReturn = '';
            break
    }
    return (
        <>
            <CardTitle title={titleCard} />
            <CardBody body={body} />
            <CardFooter name={'Назад'} onClickButton={returnHome} className={'btn-warning hover-scale form-control'}/>
        </>
    )
};

export default StartPageKaspi;