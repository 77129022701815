import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    userId: '',
    isLoading: false,
    error: ''
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers : {
        signIn(state) {
            state.isLoading = true;
        },
        signInSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            state.userId = action.payload;
        },
        signInError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
})

export default UserSlice.reducer;
export const {signIn, signInError, signInSuccess} = UserSlice.actions;
