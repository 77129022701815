import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    product: {},
    detailData: undefined
}

export const SupplierOrderSlice = createSlice({
    name: 'supplierOrder',
    initialState,
    reducers : {
        changeDetailDataSupplier(state, action) {
            state.detailData = action.payload;
        },
        changeDataSupplier(state, action) {
            state.product = action.payload;
            state.detailData = undefined;
        }
    }
})

export default SupplierOrderSlice.reducer;
export const {changeDetailDataSupplier, changeDataSupplier} = SupplierOrderSlice.actions;
