import ky from 'ky';
import cloneDeep from 'lodash.clonedeep'
import {signIn, signInError, signInSuccess} from "./UserSlice";
import {changeData, changeDataFBO} from "./StartMenuSlice";
import {changeDetailData, changeMessages, confirm, confirmSuccess} from "./SendConfirmOrderSlice";
import {changeDetailDataQty, confirmQty} from "./SendSelectedQtyFboSlice";
import {changeDetailDataSupplier} from "./SupplierOrderSlice";

export const getUser = () => async (dispatch)=> {

    try {
        dispatch(signIn())

        const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};

        let userId = initDataUnsafe?.user?.id || '';

        if (!userId) {
            userId = localStorage.getItem('userId') || '';
            if (!userId) {
                throw 'Отсутствуют данные для авторизации...';
            }
        }
        const response = await ky.get(`https://base.msk.luc.kz/utd/hs/fbs/users/${userId}`);
        if (response.status === 200) {
            dispatch(signInSuccess(userId));
            localStorage.setItem('userId', userId);
        }
    } catch (error) {
        console.log(error)
        dispatch(signInError('Ошибка авторизации пользователя...'));
        localStorage.removeItem('userId');
    }
}

export const getOrdersFBS = (marketplace) => async (dispatch) => {
    try {
        const responseData = await ky.get(
            `https://base.msk.luc.kz/utd/hs/fbs/picking?marketplace=${marketplace}`
        ).json();
        dispatch(changeData(responseData));
    } catch (error) {
        dispatch(changeData(undefined));
        console.log(error);
    }
}

export const sendConfirmOrder = (sendData, detailDataBase) => async (dispatch) => {

    dispatch(confirm(true));
    let totalCountConfirm = sendData.collected;

    const detailData = cloneDeep(detailDataBase);

    let messages = [];

    for (const ind in detailData.orders) {

        const order = detailData.orders[ind];

        let chars = order.name.split(' - (')
        if (sendData.vendor_code === chars[0]){

            for (const indSize in order.sizes) {

                const size = order.sizes[indSize];

                if (size.name === sendData.size) {
                    for (const indOrder in size.orders) {

                        const orderNumberData = size.orders[indOrder];

                        if (orderNumberData.total === 0) {
                            continue;
                        }

                        sendData.order_number = orderNumberData.number;

                        if (totalCountConfirm >= orderNumberData.total) {
                            // Если количество нужное больше или равно количеству по одному заказу
                            // обнуляем количество локально для данного заказа
                            sendData.collected = orderNumberData.total;
                        } else {
                            sendData.collected = totalCountConfirm;
                        }

                        try {
                            const responseData = await ky.post(
                                'https://base.msk.luc.kz/utd/hs/fbs/picking',
                                {json: sendData,
                                headers: {
                                    "Content-Type": "text/plain;charset=UTF-8"
                                },
                                throwHttpErrors: false},
                            );
                            const jsonResponse = await responseData.json();
                            if (responseData.status === 200) {
                                messages.push({success: true, message: `${orderNumberData.number} - ${jsonResponse.message}`});

                                // Очищаем в детальных данных успешно отправленные заказы

                                totalCountConfirm -= sendData.collected;
                                orderNumberData.total -= sendData.collected;
                                size.detail.total -= sendData.collected;

                                if (!orderNumberData.total) {
                                    // size.orders.splice(indOrder, 1);
                                    detailData.totalCountOrders -= 1;
                                }

                            } else {
                                messages.push({success: false, message: `${orderNumberData.number} - ${jsonResponse.message}`});
                            }
                        } catch (error) {
                            console.log(error);
                            messages.push({success: false, message: `${orderNumberData.number} - Ошибка отправки запроса на сервер.`})
                        }


                        if (!totalCountConfirm) break;

                    }

                    if (!size.detail.total) {
                        order.sizes.splice(indSize, 1);
                    }

                    break;
                }
            }

            if (!order.sizes.length) {
                detailData.orders.splice(ind, 1);
            }

            break;
        }
    }

    if (!detailData.totalCountOrders) {
        detailData.orders = [];
    }

    dispatch(changeDetailData(detailData));
    dispatch(changeMessages(messages));

}

export const getDataFBO = () => async (dispatch) => {
    try {
        const responseData = await ky.get(
            `https://base.msk.luc.kz/utd/hs/fbo/picking`,
            {
                timeout: 30000
            }
        ).json();
        dispatch(changeDataFBO(responseData));
    } catch (error) {
        dispatch(changeDataFBO([]));
        console.log(error);
    }
}

export const sendConfirmFbo = (sendData, sendSize, sendVendorCode, collected, detailDataBase) => async (dispatch) => {

    dispatch(confirmQty(true));

    const detailData = cloneDeep(detailDataBase);

    let messages = [];

    for (const ind in detailData.orders) {

        const order = detailData.orders[ind];

        let chars = order.name.split(' - (')
        if (sendVendorCode === chars[0]){

            for (const indSize in order.sizes) {

                const size = order.sizes[indSize];

                if (size.name === sendSize) {

                    try {
                        const responseData = await ky.post(
                            'https://base.msk.luc.kz/utd/hs/fbo/picking',
                            {json: sendData,
                            headers: {
                                "Content-Type": "text/plain;charset=UTF-8"
                            },
                            throwHttpErrors: false},
                        );
                        const jsonResponse = await responseData.json();
                        if (responseData.status === 200) {
                            messages.push({success: true, message: `${sendVendorCode} - ${jsonResponse.message}`});

                            // Очищаем в детальных данных успешно отправленные заказы

                            detailData.totalCountProducts -= (!collected) ? size.detail.total : collected;
                            size.detail.total -= (!collected) ? size.detail.total : collected;

                        } else {
                            messages.push({success: false, message: `${sendVendorCode} - ${jsonResponse.message}`});
                        }
                    } catch (error) {
                        console.log(error);
                        messages.push({success: true, message: `${sendVendorCode} - Ошибка отправки запроса на сервер.`})
                    }

                    if (!size.detail.total) {
                        order.sizes.splice(indSize, 1);
                    }
                    break;
                }
            }

            if (!order.sizes.length) {
                detailData.orders.splice(ind, 1);
            }

            break;
        }
    }

    if (!detailData.totalCountProducts) {
        detailData.orders = [];
    }

    dispatch(changeDetailDataQty(detailData));

    dispatch(changeMessages(messages));
}

export const getAllProductsByVendorCode = (vendorCode) => async (dispatch) => {
    try {
        const responseData = await ky.post(
            'https://base.msk.luc.kz/utd/hs/fbs/product/',
            {
                json: {
                    vendor_code: vendorCode
                },
                timeout: 5000,
                throwHttpErrors: false,
                headers: {
                    "Content-Type": "text/plain;charset=UTF-8"
                }
            }
        ).json();

        dispatch(changeDetailDataSupplier(responseData));
    } catch (error) {
        dispatch(changeDetailDataSupplier(undefined));
        console.log(error);
    }
}

export const createSupplierOrder = (sendData) => async (dispatch) => {

    dispatch(confirm(true));
    let messages = [];

    try {
        const responseData = await ky.post(
            'https://base.msk.luc.kz/utd/hs/fbs/supplier_order',
            {
                json: sendData,
                throwHttpErrors: false,
                headers: {
                    "Content-Type": "text/plain;charset=UTF-8"
                }
            }
        ).json();
        messages.push({success: true, message: responseData.message})
    } catch (error) {
        messages.push({success: false, message: 'Ошибка отправки запроса на создание заказа поставщику.'})
        console.log(error);
    }

    dispatch(confirmSuccess())
    dispatch(changeMessages(messages));
}